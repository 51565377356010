import Reflux from "reflux";
import { useSyncExternalStore } from "use-sync-external-store/shim";
import { CountriesActions } from "actions/actions";
import type { Country, Office, Template } from "schema/shared";
import { http } from "util/fetch";

type CountryKey = "all" | "allowed";

type InitialState = Record<CountryKey, readonly Country[]>;

export const Countries = Reflux.createStore({
  init() {
    this.listenToMany(CountriesActions);
    this.countries = {};
  },

  getInitialState(): InitialState {
    return this.countries;
  },

  async reload() {
    const url = "/api/v1/countries/";
    const countries = await http.get(url);
    this.add_to_cache("all", countries);
  },

  async reload_allowed() {
    const url = "/api/v1/countries/?only_allowed=true";
    const countries = await http.get(url);
    this.add_to_cache("allowed", countries);
  },

  async update_template(country_id: string, template: Template | null) {
    const json = {
      country: {
        cv_template_id: template?._id ?? null,
        cv_template_type: template?.template_type ?? null,
      },
    };

    const url = `/api/v1/countries/${country_id}`;
    const updated_country = await http.put(url, { body: json });
    this.replace_cache_for_all_keys(updated_country);
  },

  async update_office_template(
    country_id: string,
    office_id: string,
    template: Template | null,
  ) {
    const json = {
      office: {
        cv_template_id: template?._id ?? null,
        cv_template_type: template?.template_type ?? null,
      },
    };
    const url = `/api/v1/countries/${country_id}/offices/${office_id}`;
    const updated_office = await http.put<Office>(url, { body: json });
    const new_country_id = updated_office.country_id;
    this.replace_office_cache_for_all_keys(new_country_id, updated_office);
  },

  async update_office(
    old_country_id: string,
    office_id: string,
    updated_office: Office,
  ) {
    const json = { office: {} };

    const name = updated_office["name"];
    if (name) {
      json["office"]["name"] = name;
    }
    const country_id = updated_office["country_id"];
    if (country_id && old_country_id !== country_id) {
      json["office"]["country_id"] = country_id;
    }

    const url = `/api/v1/countries/${old_country_id}/offices/${office_id}`;
    updated_office = await http.put(url, { body: json });
    const new_country_id = updated_office.country_id;
    if (old_country_id === new_country_id) {
      this.replace_office_cache_for_all_keys(new_country_id, updated_office);
    } else {
      this.remove_office_for_all_keys(old_country_id, office_id);
      this.add_office_for_all_keys(new_country_id, updated_office);
    }
  },

  async create_office(new_office: Office) {
    const country_id = new_office["country_id"];
    const json = { name: new_office["name"] };
    const url = `/api/v1/countries/${country_id}/offices`;
    const office = await http.post(url, { body: json });
    this.add_office_for_all_keys(country_id, office);
  },

  async delete_office(country_id: string, office_id: string) {
    const url = `/api/v1/countries/${country_id}/offices/${office_id}`;
    await http.delete(url);
    this.remove_office_for_all_keys(country_id, office_id);
  },

  async create_country(new_country: Country) {
    const json = { country: new_country };
    const url = `/api/v1/countries`;
    await http.post(url, { body: json });
    await this.reload();
  },

  async update_country(country_id: string, updated_country: Country) {
    const json = { country: updated_country };
    const url = `/api/v1/countries/${country_id}`;
    await http.put(url, { body: json });
    await this.reload();
  },

  // TODO: this endpoint does not exist yet
  async delete_country(country_id: string) {
    const url = `/api/v1/countries/${country_id}`;
    await http.delete(url);
    await this.reload();
  },

  replace_cache_for_all_keys(country: Country) {
    Object.keys(this.countries).forEach((key) => {
      this.replace_cache_for(key, country);
    });
  },

  replace_office_cache_for_all_keys(
    country_id: string,
    updated_office: Office,
  ) {
    Object.keys(this.countries).forEach((key) => {
      this.replace_office_cache_for(key, country_id, updated_office);
    });
  },

  add_office_for_all_keys(country_id: string, new_office: Office) {
    Object.keys(this.countries).forEach((key) => {
      this.add_to_office_cache_for(key, country_id, new_office);
    });
  },

  remove_office_for_all_keys(country_id: string, office_id: string) {
    Object.keys(this.countries).forEach((key) => {
      this.remove_from_office_cache_for(key, country_id, office_id);
    });
  },

  replace_cache_for(key: CountryKey, updated_country: Country) {
    const new_countries = (this.countries[key] || []).map((country) => {
      if (updated_country._id === country._id) {
        return updated_country;
      }
      return country;
    });
    this.add_to_cache(key, new_countries);
  },

  replace_office_cache_for(
    key: CountryKey,
    country_id: string,
    updated_office: Office,
  ) {
    const new_countries = (this.countries[key] || []).map((country) => {
      if (country_id === country._id) {
        return this.replace_office_for_country(country, updated_office);
      }
      return country;
    });
    this.add_to_cache(key, new_countries);
  },

  add_to_office_cache_for(
    key: CountryKey,
    country_id: string,
    new_office: Office,
  ) {
    const new_countries = (this.countries[key] || []).map((country) => {
      if (country_id === country._id) {
        return this.add_office_for_country(country, new_office);
      }
      return country;
    });
    this.add_to_cache(key, new_countries);
  },

  remove_from_office_cache_for(
    key: CountryKey,
    country_id: string,
    office_id: string,
  ) {
    const new_countries = (this.countries[key] || []).map((country) => {
      if (country_id === country._id) {
        return this.remove_office_for_country(country, office_id);
      }
      return country;
    });
    this.add_to_cache(key, new_countries);
  },

  replace_office_for_country(country: Country, updated_office: Office) {
    const new_offices = (country.offices || []).map((office) => {
      if (updated_office._id === office._id) {
        return updated_office;
      }
      return office;
    });
    country["offices"] = new_offices;
    return country;
  },

  add_office_for_country(country: Country, new_office: Office) {
    const new_offices = country.offices || [];
    new_offices.push(new_office);
    country.offices = new_offices;
    return country;
  },

  remove_office_for_country(country: Country, office_id: string) {
    const new_offices = (country.offices || []).filter(
      (office) => office._id !== office_id,
    );
    country.offices = new_offices;
    return country;
  },

  add_to_cache(key: CountryKey, countries: Country[]) {
    this.countries[key] = countries;
    this.trigger(this.countries);
  },
});

// we need to return something that is `===` with the previous value, or
// `useSyncExternalStore` ends up in stack overflow
const emptyArray = [];

function subscribeAllowed(callback: (countries: readonly Country[]) => void) {
  const subscription = Countries.listen((countries: InitialState) => {
    callback(countries.allowed || emptyArray);
  }, null);
  return () => subscription();
}

function getSnapshotAllowed() {
  return ((Countries as any).countries as InitialState).allowed || emptyArray;
}

export function useAllowedCountries() {
  return useSyncExternalStore(subscribeAllowed, getSnapshotAllowed);
}

function subscribeAll(callback: (countries: readonly Country[]) => void) {
  const subscription = Countries.listen((countries: InitialState) => {
    callback(countries.all || emptyArray);
  }, null);
  return () => subscription();
}

function getSnapshotAll() {
  return ((Countries as any).countries as InitialState).all || emptyArray;
}

export function useAllCountries() {
  return useSyncExternalStore(subscribeAll, getSnapshotAll);
}
