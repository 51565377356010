import { Alert, Button, Link } from '@cvpartner/design-system';
import { ArrowRight, Mail01 } from '@cvpartner/design-system/icons/line';
import type React from 'react';
import { I18n } from 'util/translations';
import {
  ListItemLink,
  LoginLinkWrapper,
  SupportText,
  Wrapper,
} from './shared/Modern';

interface Props {
  field: string;
  user_id: string;
  federationGroupMembers: readonly any[];
  supportEmail: string;
}

export const UserAuthenticatedButDoesNotExistError: React.VFC<Props> = ({
  field,
  user_id,
  federationGroupMembers,
  supportEmail,
}) => {
  const defaultSupportEmail = 'support@flowcase.com';

  return (
    <Wrapper
      heading={I18n.t('signin.header.title_missing_user')}
      subheading={
        federationGroupMembers.length > 0
          ? I18n.t('signin.alert.error_msg_company_group')
          : I18n.t('signin.header.subtitle_missing_user').split('\n\n')
      }
      alert={
        <Alert
          type="error"
          title={`There was no user account${field ? ` with ${field}` : ''}${user_id ? ` (${user_id})` : ''}`}
        />
      }
    >
      {federationGroupMembers.length > 0 && (
        <>
          <ul>
            {federationGroupMembers.map((member) => {
              const [subdomain] = member.subdomains;
              const federationGroupMemberUrl = `https://${subdomain}.flowcase.com`;

              return (
                <ListItemLink key={subdomain}>
                  <Button
                    variant="link"
                    href={federationGroupMemberUrl}
                    trailingIcon={ArrowRight}
                    size="s"
                  >
                    {federationGroupMemberUrl}
                  </Button>
                </ListItemLink>
              );
            })}
          </ul>
          {I18n.t('signin.header.subtitle_missing_user')
            .split('\n\n')
            .map((text, index) => (
              <LoginLinkWrapper key={index}>
                <SupportText>{text}</SupportText>
              </LoginLinkWrapper>
            ))}
        </>
      )}
      {supportEmail !== defaultSupportEmail && (
        <LoginLinkWrapper>
          <Link leadingIcon={Mail01} href={`mailto:${supportEmail}`}>
            {I18n.t('signin.contact_manager')}
          </Link>
        </LoginLinkWrapper>
      )}
    </Wrapper>
  );
};
