import { Alert, StyleProvider } from '@cvpartner/design-system';
import { LinkExternal01 } from '@cvpartner/design-system/icons/line';
import * as React from 'react';
import { type Breadcrumb, Breadcrumbs } from 'components/shared/Breadcrumbs';
import { I18n } from 'util/translations';
import {
  alertWrapper,
  checkboxWrapper,
  copyright,
  footer,
  list,
  listItemLink,
  loginButtonWrapper,
  loginLinkWrapper,
  outerWrapper,
  supportText,
  wrapper,
} from './Modern.css';
import { LoginCard } from './modern/LoginCard';
import { Logo } from './modern/Logo';

const Footer: React.VFC = () => {
  const thisYear = new Date().getFullYear();

  return (
    <footer className={footer}>
      <div className={copyright}>{thisYear} &copy; Flowcase</div>
    </footer>
  );
};

const LoginAlert: React.VFC<{ children: React.ReactNode }> = ({ children }) => (
  <div className={alertWrapper}>{children}</div>
);

// We on purpose ignores the props here as we don't want its size
const FlowcaseIcon: React.VFC = () => (
  <svg fill="none" width="40" height="40" viewBox="0 0 40 40">
    <rect width="40" height="40" fill="#F5F7FF" rx="8" />
    <path
      fill="#5B53FF"
      d="M29.9942 12.3581V10H19.1613c-1.8462 0-3.343 1.7465-3.343 3.9008v4.9405H13.343c-1.8462 0-3.343 1.7465-3.343 3.9008V30h2.6383v-8.5695l.1922-.2242H29.039v-2.3718H18.4508v-6.2521l.1922-.2243H30h-.0058Z"
    />
  </svg>
);

const rebrandLocalStorageKey = 'showRebrandBanner';
const RebrandInfo: React.VFC = () => {
  const [showIcon, setShowIcon] = React.useState(() => {
    const currentValue = localStorage.getItem(rebrandLocalStorageKey);

    return currentValue !== 'false';
  });

  const onClose = React.useCallback(() => {
    setShowIcon(false);
    localStorage.setItem(rebrandLocalStorageKey, 'false');
  }, []);

  if (!showIcon) {
    return null;
  }

  return (
    <Alert
      type="message"
      title={I18n.t('signin.alert.new_name')}
      description={I18n.t('signin.alert.signin_msg_new_name')}
      onClose={onClose}
      icon={FlowcaseIcon}
      actions={[
        {
          displayText: I18n.t('signin.alert.bt_new_name_article'),
          link: 'https://www.flowcase.com/blog/cv-partner-is-now-flowcase',
          trailingIcon: LinkExternal01,
        },
      ]}
    />
  );
};

// the header kinda floats in place up until rebrand is completely done. So just
// compensate for it, if present.
const headerElement = document.querySelector('header#product-header');
const hasHeaderElement = headerElement != null;

export const Wrapper: React.VFC<{
  heading: string;
  subheading?: string | readonly string[];
  rebrandInfo?: boolean;
  alert?: React.ReactNode;
  breadcrumbs?: readonly Breadcrumb[];
  children?: React.ReactNode;
}> = ({ heading, subheading, rebrandInfo, alert, breadcrumbs, children }) => (
  <StyleProvider style="modern">
    <div className={outerWrapper({ hasHeaderElement })}>
      {breadcrumbs && <Breadcrumbs breadcrumbs_tree={breadcrumbs} />}
      <div className={wrapper({ hasHeaderElement })}>
        <LoginAlert>
          {alert}
          {rebrandInfo && <RebrandInfo />}
        </LoginAlert>
        {hasHeaderElement ? null : <Logo />}
        <main>
          <LoginCard
            heading={heading}
            subheading={subheading}
            hasHeaderElement={hasHeaderElement}
          >
            {children}
          </LoginCard>
        </main>
        <Footer />
      </div>
    </div>
  </StyleProvider>
);

export const List: React.VFC<{ children: React.ReactNode }> = ({
  children,
}) => <ul className={list}>{children}</ul>;

export const LoginButtonWrapper: React.VFC<{
  center: boolean;
  children: React.ReactNode;
}> = ({ center, children }) => (
  <div className={loginButtonWrapper({ center })}>{children}</div>
);

export const LoginLinkWrapper: React.VFC<{
  children: React.ReactNode;
}> = ({ children }) => <div className={loginLinkWrapper}>{children}</div>;

export const CheckboxWrapper: React.VFC<{
  children: React.ReactNode;
}> = ({ children }) => <div className={checkboxWrapper}>{children}</div>;

export const SupportText: React.VFC<{
  children: React.ReactNode;
}> = ({ children }) => <p className={supportText}>{children}</p>;

export const ListItemLink: React.VFC<{
  children: React.ReactNode;
}> = ({ children }) => <li className={listItemLink}>{children}</li>;
