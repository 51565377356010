import { Button, ComboBox, ComboBoxItem } from '@cvpartner/design-system';
import { ArrowRight } from '@cvpartner/design-system/icons/line';
import * as React from 'react';
import type { MongoEntity } from 'schema/shared';
import { I18n } from 'util/translations';
import { List, LoginButtonWrapper, Wrapper } from './shared/Modern';
import { CardContent } from './shared/modern/LoginCard';

interface GoogleAppsDomain extends MongoEntity {
  company_id: string;
  disable_user_creation: boolean;
  domain: string;
}

interface Props {
  googleAppsDomains: readonly GoogleAppsDomain[];
}

export const SelectDomain: React.VFC<Props> = ({ googleAppsDomains }) => {
  const [selectedDomain, setSelectedDomain] = React.useState<string>();
  const domains = React.useMemo(
    () =>
      googleAppsDomains.map((domain) => ({
        ...domain,
        id: domain.domain,
      })),
    [googleAppsDomains],
  );

  return (
    <Wrapper
      heading={I18n.t('signin.header_title_select_domain')}
      subheading={I18n.t('signin.header_subtitle_google')}
      rebrandInfo={true}
    >
      <CardContent>
        {domains.length > 3 ? (
          <>
            <ComboBox
              onSelectionChange={(k) => {
                if (typeof k === 'string') {
                  setSelectedDomain(k);
                }
              }}
              placeholder={I18n.t('signin.select_domain_placeholder')}
              defaultItems={domains}
            >
              {({ domain }) => <ComboBoxItem>{domain}</ComboBoxItem>}
            </ComboBox>
            <Button
              variant="link"
              href={`/auth/google_oauth2?domain=${selectedDomain}`}
              disabled={!selectedDomain}
            >
              {I18n.t('signin.continue_button')}
            </Button>
          </>
        ) : (
          <List>
            {domains.map(({ domain }) => (
              <li key={domain}>
                <LoginButtonWrapper center={false}>
                  <Button
                    trailingIcon={ArrowRight}
                    variant="link"
                    href={`/auth/google_oauth2?domain=${domain}`}
                    size="s"
                  >
                    {domain}
                  </Button>
                </LoginButtonWrapper>
              </li>
            ))}
          </List>
        )}
      </CardContent>
    </Wrapper>
  );
};
