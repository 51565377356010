import { Alert, Link } from '@cvpartner/design-system';
import { ArrowRight, Mail01 } from '@cvpartner/design-system/icons/line';
import type React from 'react';
import { I18n } from 'util/translations';
import { LoginLinkWrapper, Wrapper } from './shared/Modern';
import { useTranslatedErrorMessage } from './shared/TranslateErrorMessage';

interface Props {
  message: string;
  errParams: Record<string, string>;
}

export const OauthFailure: React.VFC<Props> = ({ message, errParams }) => {
  const translatedMessage = useTranslatedErrorMessage(message, errParams);

  return (
    <Wrapper
      heading={I18n.t('signin.header.title_sso')}
      subheading={I18n.t('signin.header.subtitle_sso_issue_persists')}
      alert={
        <Alert
          type="error"
          title={I18n.t('signin.alert.signin_failed')}
          description={translatedMessage}
          actions={[
            {
              link: '/login',
              displayText: I18n.t(
                'resetting_password.alert.error_msg_try_again',
              ),
              trailingIcon: ArrowRight,
            },
          ]}
        />
      }
    >
      <LoginLinkWrapper>
        <Link href="mailto:support@flowcase.com" leadingIcon={Mail01}>
          {I18n.t('signin.link_contact_support')}
        </Link>
      </LoginLinkWrapper>
    </Wrapper>
  );
};
